<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Store from "@/components/personalizations/templates-store.vue"

import {
	
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Template Store",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
    Layout,
    PageHeader,
    Store
  },
  data() {
	return {
		title: this.$t('events.title'),
		showTemplatesAvailable: true
  }
  },
  
  mounted() {
    
  },
  created(){
		
	},
  methods: {
	},
  watch : {
    showTemplatesAvailable: {
      immediate: true,
      handler(newVal){
        if(newVal == false){
          this.$router.push({
            path: "/personalizations",
          }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        });
        }
      }
    }
  }
};
</script>

<template>
	<Layout>
		<PageHeader title="Templates Store" :items="items" />
		<div class="row">
      <b-modal id="templates-modal" size="xl" v-model="showTemplatesAvailable" title="Templates Store" hide-footer no-close-on-backdrop scrollable>
        <Store :showUseButton="false" :hideGoalsFilter="true"/>
      </b-modal>
    </div>
  </Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }
</style>

<style>
#templates-modal .modal-body {
  background-color: var(--bs-body-bg);
  min-height: 500px;
}
</style>